.data-card span {
  @apply text-xs text-primary-400;
}

.data-card p {
  @apply text-lg font-medium text-gray-800;
}

.data-card {
  @apply mr-5;
}
