@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;600;800&display=swap");

html {
  scroll-behavior: smooth;
  font-family: "Raleway", sans-serif;
}

svg {
  display: inline !important;
}

* {
  margin: 0;
  padding: 0;
  line-height: 1.5;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import "~antd/dist/antd.css"; */

/* NAVIGATION LINKS */
.nav-link {
  @apply block my-5 py-3 text-white font-light px-4 text-base  rounded-2xl transition duration-200;
}

.nav-link-active {
  @apply block my-5 py-3 shadow-md text-primary-100 font-light px-4 text-base rounded-2xl  bg-secondary-100 transition duration-200;
}

.nav-icon {
  @apply ml-5 inline;
}

.nav-active-icon {
  @apply ml-5 inline;
}

.form-input-full {
  @apply appearance-none border-none bg-white border  border-secondary-200 text-secondary-200 block w-full  rounded-xl py-4 px-6 mb-3 leading-tight focus:border-secondary-200;
}

.prod-label {
  @apply text-xs block mb-2 font-semibold text-secondary-200;
}
.form-input-outline {
  @apply appearance-none text-base font-normal  bg-white border  border-secondary-200 text-secondary-200 block w-full  rounded-xl py-3 px-6 mb-3 leading-tight focus:border-secondary-200;
}

.form-input-outline:disabled {
  @apply appearance-none cursor-not-allowed bg-gray-100 border  border-gray-500 text-secondary-200 block w-full  rounded-xl py-3 px-6 mb-3 leading-tight focus:border-secondary-200;
}

.btn-primary {
  @apply bg-primary-200 text-white text-center p-3 text-base font-normal rounded-2xl hover:bg-secondary-400 cursor-pointer;
}

.btn-secondary {
  @apply bg-secondary-200 text-white text-center p-3 rounded-2xl hover:bg-secondary-400 cursor-pointer;
}

.btn-primary:disabled {
  @apply bg-gray-600 cursor-not-allowed;
}
/* remove outline from all inputs  */
textarea:focus,
input:focus {
  outline: none;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: none !important;
}

/* remove arrows from number input*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.tab-selected {
  @apply bg-secondary-200 text-white px-10 md:px-5 py-2 rounded-xl  text-base font-normal;
}

.tab-selected:focus {
  @apply outline-none;
}

.tab-normal {
  @apply text-secondary-200 px-10 py-2 rounded-xl  text-base font-normal;
}

.tab-list {
  @apply bg-primary-100 rounded-xl my-3 block md:inline-block bg-opacity-30;
}

.desc {
  @apply font-light text-sm;
}

.tab-active {
  @apply text-base inline-flex font-semibold items-center gap-3 p-4 text-secondary-200 border-b-2 border-secondary-200 focus:outline-none;
}

.tab-inactive {
  @apply inline-flex text-base font-medium items-center gap-3 p-4 text-gray-500 border-b-2 border-none;
}
